import SegmentRule from '@/engine/rules/segment-rule'
import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'
import Segment from '@/engine/models/segment'
import States from '@/engine/common/states'
import audioService from '@/services/audio-service'

export default class ConquerAllPayRentRule extends SegmentRule {

    protected segmentSatisfied(turnState: TurnState, state: GameState, segment: Segment): boolean {
        return state.gameplay.enabledConquerAll
            && segment.id !== 'b00'
            && segment.id !== 'e00'
            && segment.owner != null
    }

    protected segmentAction(turnState: TurnState, state: GameState, segment: Segment): GameState {
        // Gäste zahlen immer drauf.
        if (segment.owner != null && segment.owner.id !== state.team.id) {
            this.payForOwnedSegments(state, segment)
            this.playAudio(turnState)
        } else if (state.gameplay.enabledFriendlyFire) {
            switch (segment.alph) {
                case 'd':
                    if (segment.houses < state.gameplay.maxHouses) {
                        this.payForOwnedSegments(state, segment)
                        this.playAudio(turnState)
                    } else if (segment.villas >= state.gameplay.maxVillas) {
                        this.payForOwnedSegments(state, segment)
                        this.playAudio(turnState)
                    }
                    break
                case 't':
                    if (segment.houses >= state.gameplay.maxHouses) {
                        this.payForOwnedSegments(state, segment)
                        this.playAudio(turnState)
                    }
                    break
                default:
                    this.payForOwnedSegments(state, segment)
                    this.playAudio(turnState)
                    break
            }
        }
        return state
    }

    private payForOwnedSegments(state: GameState, segment: Segment) {
        // Take all segments that are in the same region and that are owned by
        // the owner of the hit segment.
        const ownedSegments = state
            .getSegmentsForRegion(segment.num)
            .filter((s) => s.owner === segment.owner)

        States.payForSegments(state, ownedSegments)
    }

    private playAudio(turnState: TurnState) {
        if (turnState.turnComplete === false) {
            audioService.playPayRent()
        }
    }
}
