import Rule from '@/engine/rules/rule'
import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'
import audioService from '@/services/audio-service'

export default class DefeatRule implements Rule {

    public satisfied(turnState: TurnState, state: GameState): boolean {
        return state.hasZeroedTeams
    }

    public action(turnState: TurnState, state: GameState): GameState {
        // Wenn ein Spieler eleminiert wird und es noch mindestens zwei
        // aktive Spieler gibt, spiele 'Spieler eleminiert' Sound.
        if (state.numActiveTeams > 1) {
            audioService.playDefeated()
        }

        for (const team of state.zeroedTeams) {
            this.freeSegments(state)
            team.state = 'cleared'
            // Show defeat dialog iff team defeated itself and there is at
            // least one other active team.
            if (state.numActiveTeams > 1 && team.id === state.team.id) {
                state.selfDefeat = true
                state.showSelfDefeatDialog = true
            }
        }

        // If Easy Südpol Plus is enabled, deactivate Easy Südpol.
        if (state.gameplay.enabledESPPlus) {
            state.gameplay.enabledBBE = false
        }

        return state
    }

    private freeSegments(state: GameState) {
        const loserIds = state.zeroedTeams.map((team) => team.id)

        for (const segment of state.segments) {
            if (segment.owner != null && loserIds.includes(segment.owner.id)) {
                segment.owner = null
                // Neue Regel: Häuser und Villen werden nur in Conquer-All
                // sofort zurückgesetzt. In allen anderen Modi bleiben die
                // Immobilien erhalten und können vom Gegner beschlagnahmt
                // werden.
                if (state.gameplay.enabledConquerAll) {
                    segment.houses = 0
                    segment.villas = 0
                }
            }
        }
    }
}
