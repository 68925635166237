export default class Players {

    public static pickNext(
        teamId: number,
        playerId: number,
        teamMax: number,
        playerMax: number): [number, number] {
        if (teamId < teamMax - 1) {
            teamId++
        } else {
            teamId = 0
            playerId++
        }
        if (playerId >= playerMax) {
            playerId = 0
        }
        return [teamId, playerId]
    }
}
