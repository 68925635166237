import Team from './team'

export default class Segment {
    public id: string = ''
    public owner: null | Team = null
    public houses: number = 0
    public villas: number = 0

    get alph(): string {
        return this.id.substring(0, 1)
    }

    get num(): string {
        return this.id.substring(1, 3)
    }
}
