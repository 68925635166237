import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'
import Segment from '@/engine/models/segment'
import States from '@/engine/common/states'
import audioService from '@/services/audio-service'

export default function bbe(turnState: TurnState, state: GameState, segment: Segment) {
  if (state.gameplay.enabledBBE) {
    States.deal(state, segment)
    state.bullsDealt++
    if (turnState.turnComplete === false) {
      audioService.playBullDeal()
    }
  } else {
    States.pay(state, segment)
    if (turnState.turnComplete === false) {
      audioService.playPayRent()
    }
  }
}
