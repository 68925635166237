


























import { Component, Prop, Vue } from 'vue-property-decorator'
import GameState from '@/engine/models/game-state'
import KeypadInputDialog from '@/components/keypad-input-dialog.vue'
import States from '@/engine/common/states'

@Component
export default class ShotsTable extends Vue {
  @Prop()
  private state!: GameState

  @Prop()
  private submitCallback!: (shots: string[]) => void

  private isNegativeShot(n: number): boolean {
    const shot = this.state.history[n]

    if (!shot) {
      return false
    }

    const segment = this.state.getSegment(shot)

    if (segment === undefined) {
      return false
    }

    if (segment.owner != null) {
      return segment.owner !== this.state.team
    }
    return false
  }

  private infoForShot(n: number): string {
    const shot = this.state.history[n]

    if (!shot) {
      return '-'
    }

    const segment = this.state.getSegment(shot)

    if (segment === undefined) {
      return 'Miss'
    }

    if (this.isNegativeShot(n)) {
      return `${-States.getHitpoints(this.state, segment)}`
    } else {
      switch (segment.alph) {
        case 'd':
          return `Double ${segment.num}`
        case 'u':
          return `High ${segment.num}`
        case 't':
          return `Triple ${segment.num}`
        case 'l':
          return `Low ${segment.num}`
        case 'b':
          return 'Südpol'
        case 'e':
          return 'Nordpol'
        default:
          return ''
      }
    }
  }

  private openShotsInputDialog(n: number) {
    const dialog = this.$refs.dialog as KeypadInputDialog
    dialog.open(n)
  }

  private onShot(shot: string): void {
    this.$emit('shot', shot)
  }

  private onReplace(n: number, shot: string): void {
    this.$emit('replace', n, shot)
  }
}
