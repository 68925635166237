import Vue from 'vue'
import Router from 'vue-router'
import Game from './views/Game.vue'
import Setup from './views/Setup.vue'
import Home from './views/Home.vue'
import Help from './views/Help.vue'
import Legal from './views/Legal.vue'
import About from './views/About.vue'
import Tutorial from './views/Tutorial.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/setup',
      name: 'setup',
      component: Setup,
    },
    {
      path: '/game',
      name: 'game',
      component: Game,
    },
    {
      path: '/help',
      name: 'help',
      component: Help,
    },
    {
      path: '/legal',
      name: 'legal',
      component: Legal,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/tutorial',
      name: 'tutorial',
      component: Tutorial,
    },
  ],
})
