











import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Cookies extends Vue {

  public snackbar: boolean = true
}
