import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'
import Segment from '@/engine/models/segment'
import Rule from '@/engine/rules/rule'

export default abstract class SegmentRule implements Rule {

  public satisfied(turnState: TurnState, state: GameState): boolean {
    const segment = state.getSegment(state.shot)
    if (segment) {
      return this.segmentSatisfied(turnState, state, segment)
    }
    return false
  }

  public action(turnState: TurnState, state: GameState): GameState {
    const segment = state.getSegment(state.shot)
    if (segment) {
      return this.segmentAction(turnState, state, segment)
    }
    // TODO: Im Fehlerfall neuen State zurückzugeben ist nur Notlösung.
    return new GameState()
  }

  protected abstract segmentSatisfied(turnState: TurnState, state: GameState, segment: Segment): boolean

  protected abstract segmentAction(turnState: TurnState, state: GameState, segment: Segment): GameState
}
