import GameState from '@/engine/models/game-state'
import Segment from '@/engine/models/segment'
import { GameplayHitpoints } from '@/engine/models/gameplay-hitpoints'
import Player from '@/engine/models/player'
import Rule from '@/engine/rules/rule'
import DefeatRule from '@/engine/rules/defeat-rule'
import VictoryRule from '@/engine/rules/victory-rule'
import Victory11RRule from '@/engine/rules/victory-11r-rule'
import BuildHouseRule from '@/engine/rules/build-house-rule'
import BuildVillaRule from '@/engine/rules/build-villa-rule'
import PayRentRule from '@/engine/rules/pay-rent-rule'
import ConquerAllPayRentRule from '@/engine/rules/conquer-all-pay-rent-rule'
import BullRentRule from '@/engine/rules/bull-rent-rule'
import BullsEyeRentRule from '@/engine/rules/bulls-eye-rent-rule'
import ConquestRule from '@/engine/rules/conquest-rule'
import ConquerAllConquestRule from '@/engine/rules/conquer-all-conquest-rule'
import BullForLandConquestRule from '@/engine/rules/bull-for-land-conquest-rule'
import NuexRule from '@/engine/rules/nuex-rule'
import SchnapszahlRule from '@/engine/rules/schnapszahl-rule'
import ClearAbandonedEstateRule from '@/engine/rules/clear-abandoned-estate-rule'
import ResetDestroyRule from '@/engine/rules/reset-destroy-rule'
import AbsoluteProConquestRule from '@/engine/rules/absolute-pro-conquest-rule'
import AbsoluteProBuildHouseRule from '@/engine/rules/absolute-pro-build-house-rule'
import AbsoluteProBuildVillaRule from '@/engine/rules/absolute-pro-build-villa-rule'
import BullForLandBullsEyeRentRule from '@/engine/rules/bull-for-land-bulls-eye-rent-rule'
import BullForLandBullRentRule from '@/engine/rules/bull-for-land-bull-rent-rule'

export default class Gameplay {
  public numTeams: number = 4
  public numPlayers: number = 1
  public players: Player[] = [
    {
      avatar: 1,
      id: 0,
      name: 'Spieler 1',
      shots: 0,
      teamId: 0,
    },
    {
      avatar: 2,
      id: 1,
      name: 'Spieler 2',
      shots: 0,
      teamId: 1,
    },
    {
      avatar: 3,
      id: 2,
      name: 'Spieler 3',
      shots: 0,
      teamId: 2,
    },
    {
      avatar: 4,
      id: 3,
      name: 'Spieler 4',
      shots: 0,
      teamId: 3,
    },
  ]

  public numDarts: number = 5
  public initialScore: number = 303
  public maxHouses: number = 1
  public maxVillas: number = 1

  /** Nordpol (NP) */
  public enabledBull: boolean = false
  /** Easy Südpol (ES) */
  public enabledBBE: boolean = false
  /** Pol-for-Region (PFR) */
  public enabledBullForLand: boolean = false
  public enabledTopToBottom: boolean = false
  public enabledFriendlyFire: boolean = false
  public enabledConquerAll: boolean = false
  public enabled11R: boolean = false
  public enabled111: boolean = false
  public enabledDestroy: boolean = false
  public enabledAllOrNothing: boolean = false
  public enabledAbsolutePro: boolean = false
  public enabledESPPlus: boolean = false

  // TODO: Work with region string everywhere
  // TODO: Region strings bottomUp
  // TODO: explicit type RegionNum
  // TODO: '00'
  public regionsTopDown: string[] = [
    '20', '19', '18', '17', '16', '15', '14', '13', '12', '11',
    '10', '09', '08', '07', '06', '05', '04', '03', '02', '01',
  ]

  public hitpoints: GameplayHitpoints[] = [
    {
      id: 'b00',
      value: 25,
      calculate: (state, segment, value) => value,
    },
    {
      id: 'e00',
      value: 50,
      calculate: (state, segment, value) => value,
    },

    { id: 'l01', value: 1, calculate: this.calculateRent },
    { id: 't01', value: 1, calculate: this.calculateRent },
    { id: 'u01', value: 1, calculate: this.calculateRent },
    { id: 'd01', value: 1, calculate: this.calculateRent },

    { id: 'l02', value: 2, calculate: this.calculateRent },
    { id: 't02', value: 2, calculate: this.calculateRent },
    { id: 'u02', value: 2, calculate: this.calculateRent },
    { id: 'd02', value: 2, calculate: this.calculateRent },

    { id: 'l03', value: 3, calculate: this.calculateRent },
    { id: 't03', value: 3, calculate: this.calculateRent },
    { id: 'u03', value: 3, calculate: this.calculateRent },
    { id: 'd03', value: 3, calculate: this.calculateRent },

    { id: 'l04', value: 4, calculate: this.calculateRent },
    { id: 't04', value: 4, calculate: this.calculateRent },
    { id: 'u04', value: 4, calculate: this.calculateRent },
    { id: 'd04', value: 4, calculate: this.calculateRent },

    { id: 'l05', value: 5, calculate: this.calculateRent },
    { id: 't05', value: 5, calculate: this.calculateRent },
    { id: 'u05', value: 5, calculate: this.calculateRent },
    { id: 'd05', value: 5, calculate: this.calculateRent },

    { id: 'l06', value: 6, calculate: this.calculateRent },
    { id: 't06', value: 6, calculate: this.calculateRent },
    { id: 'u06', value: 6, calculate: this.calculateRent },
    { id: 'd06', value: 6, calculate: this.calculateRent },

    { id: 'l07', value: 7, calculate: this.calculateRent },
    { id: 't07', value: 7, calculate: this.calculateRent },
    { id: 'u07', value: 7, calculate: this.calculateRent },
    { id: 'd07', value: 7, calculate: this.calculateRent },

    { id: 'l08', value: 8, calculate: this.calculateRent },
    { id: 't08', value: 8, calculate: this.calculateRent },
    { id: 'u08', value: 8, calculate: this.calculateRent },
    { id: 'd08', value: 8, calculate: this.calculateRent },

    { id: 'l09', value: 9, calculate: this.calculateRent },
    { id: 't09', value: 9, calculate: this.calculateRent },
    { id: 'u09', value: 9, calculate: this.calculateRent },
    { id: 'd09', value: 9, calculate: this.calculateRent },

    { id: 'l10', value: 10, calculate: this.calculateRent },
    { id: 't10', value: 10, calculate: this.calculateRent },
    { id: 'u10', value: 10, calculate: this.calculateRent },
    { id: 'd10', value: 10, calculate: this.calculateRent },

    { id: 'l11', value: 11, calculate: this.calculateRent },
    { id: 't11', value: 11, calculate: this.calculateRent },
    { id: 'u11', value: 11, calculate: this.calculateRent },
    { id: 'd11', value: 11, calculate: this.calculateRent },

    { id: 'l12', value: 12, calculate: this.calculateRent },
    { id: 't12', value: 12, calculate: this.calculateRent },
    { id: 'u12', value: 12, calculate: this.calculateRent },
    { id: 'd12', value: 12, calculate: this.calculateRent },

    { id: 'l13', value: 13, calculate: this.calculateRent },
    { id: 't13', value: 13, calculate: this.calculateRent },
    { id: 'u13', value: 13, calculate: this.calculateRent },
    { id: 'd13', value: 13, calculate: this.calculateRent },

    { id: 'l14', value: 14, calculate: this.calculateRent },
    { id: 't14', value: 14, calculate: this.calculateRent },
    { id: 'u14', value: 14, calculate: this.calculateRent },
    { id: 'd14', value: 14, calculate: this.calculateRent },

    { id: 'l15', value: 15, calculate: this.calculateRent },
    { id: 't15', value: 15, calculate: this.calculateRent },
    { id: 'u15', value: 15, calculate: this.calculateRent },
    { id: 'd15', value: 15, calculate: this.calculateRent },

    { id: 'l16', value: 16, calculate: this.calculateRent },
    { id: 't16', value: 16, calculate: this.calculateRent },
    { id: 'u16', value: 16, calculate: this.calculateRent },
    { id: 'd16', value: 16, calculate: this.calculateRent },

    { id: 'l17', value: 17, calculate: this.calculateRent },
    { id: 't17', value: 17, calculate: this.calculateRent },
    { id: 'u17', value: 17, calculate: this.calculateRent },
    { id: 'd17', value: 17, calculate: this.calculateRent },

    { id: 'l18', value: 18, calculate: this.calculateRent },
    { id: 't18', value: 18, calculate: this.calculateRent },
    { id: 'u18', value: 18, calculate: this.calculateRent },
    { id: 'd18', value: 18, calculate: this.calculateRent },

    { id: 'l19', value: 19, calculate: this.calculateRent },
    { id: 't19', value: 19, calculate: this.calculateRent },
    { id: 'u19', value: 19, calculate: this.calculateRent },
    { id: 'd19', value: 19, calculate: this.calculateRent },

    { id: 'l20', value: 20, calculate: this.calculateRent },
    { id: 't20', value: 20, calculate: this.calculateRent },
    { id: 'u20', value: 20, calculate: this.calculateRent },
    { id: 'd20', value: 20, calculate: this.calculateRent },
  ]

  public rules: Rule[] = [
    new PayRentRule(),
    new ConquerAllPayRentRule(),
    new ConquestRule(),
    new ConquerAllConquestRule(),
    new BullForLandConquestRule(),
    new AbsoluteProConquestRule(),
    new BuildHouseRule(),
    new AbsoluteProBuildHouseRule(),
    new BuildVillaRule(),
    new AbsoluteProBuildVillaRule(),
    new BullRentRule(),
    new BullForLandBullRentRule(),
    new BullsEyeRentRule(),
    new BullForLandBullsEyeRentRule(),
    new SchnapszahlRule(),
    new DefeatRule(),
    new VictoryRule(),
    new Victory11RRule(),
  ]

  public turnRules: Rule[] = [
    new NuexRule(),
    // Re-evaluate defeat and victory rules.
    // NÜX rule could have defeated current player.
    new DefeatRule(),
    new VictoryRule(),
    new Victory11RRule(),
    new ClearAbandonedEstateRule(),
    new ResetDestroyRule(),
  ]

  public calculateRent(
    state: GameState,
    segment: Segment,
    value: number,
  ): number {
    let sum = value               // basis rent
    sum += segment.houses * value // rent for houses
    sum += segment.villas * value // rent for villas
    return sum
  }

  public getHitpointsFor(id: string): GameplayHitpoints | undefined {
    return this.hitpoints.find((h) => h.id === id)
  }
}
