import Gameplay from './models/gameplay'
import GameState from './models/game-state'
import Team from './models/team'
import Player from './models/player'
import Segment from './models/segment'

export default class GameStateFactory {

    // TODO: Colors sollten besser via CSS und TeamId gesetzt werden.
    private colors: string[]

    constructor() {
        this.colors = [
            '#d33232',
            '#44A8FF',
            '#1eb61e',
            '#F6862F',
        ]
    }

    public build(gameplay: Gameplay) {
        const state: GameState = new GameState()
        state.gameplay = gameplay
        state.teams = this.initTeams(gameplay)
        state.segments = this.initSegments(gameplay, state.teams)
        return state
    }

    private initTeams(gameplay: Gameplay): Team[] {
        const teams: Team[] = []
        for (let i = 0; i < gameplay.numTeams; i++) {
            teams.push(this.initTeam(gameplay, i))
        }
        return teams
    }

    private initTeam(gameplay: Gameplay, id: number): Team {
        const team: Team = {
            id,
            color: this.colors[id],
            score: gameplay.initialScore,
            players: this.initPlayers(gameplay, id),
            pointsPayed: 0,
            numHouses: 0,
            numVillas: 0,
            numRegions: 0,
            state: 'active',
        }
        return team
    }

    private initPlayers(gameplay: Gameplay, teamId: number): Player[] {
        return gameplay.players.filter((p) => p.teamId === teamId)
    }

    private initSegments(gameplay: Gameplay, teams: Team[]): Segment[] {
        const segments: Segment[] = []
        for (const hitpoints of gameplay.hitpoints) {
            const segment = new Segment()
            segment.id = hitpoints.id
            // segment.owner = teams[1]
            segments.push(segment)
        }
        return segments
    }
}
