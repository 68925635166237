

































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Gameplay from '@/engine/models/gameplay'
import GameStateFactory from '@/engine/game-state-factory'
import GameEngine from '@/engine/game-engine'
import GameState from '@/engine/models/game-state'
import DartBoard from '@/components/dart-board.vue'
import DartboardInputDialog from '@/components/dartboard-input-dialog.vue'
import WorldmapDialog from '@/components/map-dialog.vue'
import audioService from '@/services/audio-service'
import * as signalR from '@microsoft/signalr'

@Component
export default class Game extends Vue {
  @Prop()
  public engine!: GameEngine
  public worldmapDialog!: WorldmapDialog

  private desktopDialog!: DartboardInputDialog
  private board!: DartBoard
  private dartBoardDialogNotyetOpened = true
  private board2score: { [name: string]: string } = {
    '7,12': 'miss',
    '8,13': 'next',

    '0,6': 'b00',
    '0,4': 'e00',

    '6,3': 'd20',
    '5,3': 'u20',
    '4,3': 't20',
    '3,3': 'l20',

    '6,1': 'd01',
    '5,1': 'u01',
    '4,1': 't01',
    '3,1': 'l01',

    '6,2': 'd18',
    '5,2': 'u18',
    '4,2': 't18',
    '2,2': 'l18',

    '6,0': 'd04',
    '5,0': 'u04',
    '3,0': 't04',
    '1,0': 'l04',

    '5,4': 'd13',
    '4,0': 'u13',
    '2,0': 't13',
    '0,0': 'l13',

    '4,4': 'd06',
    '3,2': 'u06',
    '1,2': 't06',
    '0,2': 'l06',

    '3,4': 'd10',
    '2,1': 'u10',
    '1,1': 't10',
    '0,1': 'l10',

    '2,4': 'd15',
    '2,3': 'u15',
    '1,3': 't15',
    '0,3': 'l15',

    '2,6': 'd02',
    '2,7': 'u02',
    '0,7': 't02',
    '1,7': 'l02',

    '3,6': 'd17',
    '2,8': 'u17',
    '0,8': 't17',
    '1,8': 'l17',

    '4,6': 'd03',
    '2,10': 'u03',
    '0,10': 't03',
    '1,10': 'l03',

    '5,6': 'd19',
    '3,9': 'u19',
    '0,9': 't19',
    '1,9': 'l19',

    '6,6': 'd07',
    '4,11': 'u07',
    '2,11': 't07',
    '1,11': 'l07',

    '6,11': 'd16',
    '5,11': 'u16',
    '3,11': 't16',
    '0,11': 'l16',

    '6,9': 'd08',
    '5,9': 'u08',
    '4,9': 't08',
    '2,9': 'l08',

    '6,10': 'd11',
    '5,10': 'u11',
    '4,10': 't11',
    '3,10': 'l11',

    '6,8': 'd14',
    '5,8': 'u14',
    '4,8': 't14',
    '3,8': 'l14',

    '6,7': 'd09',
    '5,7': 'u09',
    '4,7': 't09',
    '3,7': 'l09',

    '6,5': 'd12',
    '5,5': 'u12',
    '3,5': 't12',
    '0,5': 'l12',

    '6,4': 'd05',
    '4,5': 'u05',
    '2,5': 't05',
    '1,5': 'l05',
  }
  private boardWindow: Window | null = null

  get state(): GameState {
    return this.engine.currentState
  }

  get gameplay(): Gameplay {
    return this.engine.currentState.gameplay
  }

  public openDartDialog() {
    if (this.$vuetify.breakpoint.smAndUp) {
      // It's time to attack sprechen wenn Nordpol aktiviert ist und der Dialog
      // zum ersten Mal geöffnet wird.
      if (this.dartBoardDialogNotyetOpened && this.gameplay.enabledBull) {
        this.dartBoardDialogNotyetOpened = false
        audioService.playTimeToAttack()
      }
      this.desktopDialog.open()
    }
  }

  public onNext() {
    this.board.resetSegmentsHit()
    this.engine.next()
  }

  public onShot(shot: string): void {
    this.engine.shoot(shot)

    const field = document.getElementById(shot)
    if (process.env.VUE_APP_API_URL) {
      Vue.nextTick(() => {
        const colors = []
        for (const b2s of Object.keys(this.board2score)) {
          const el = document.getElementById(this.board2score[b2s])
          if (el) {
            colors.push({ segment: b2s, color: el.style.fill })
          }
        }
        fetch(`${process.env.VUE_APP_API_URL}/board/colors`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(colors),
        }).catch((err) => {
          // console.log(err)
        })
      })
    }
  }

  public onReplace(n: number, shot: string): void {
    this.engine.replaceShot(n, shot)
  }

  public showAdDialog(mode: string) {
    // @ts-ignore
    this.$refs.adDialog.open(mode)
  }

  public onNewGame() {
    this.$router.push('/setup')
  }

  public onRevanche() {
    this.engine.reset()
  }

  public onSchnapszahl() {
    this.engine.schnapszahl()
    this.state.showSchnapszahlDialog = false
    this.desktopDialog.open()
  }

  public onDefeat() {
    this.engine.defeat()
    this.state.showSelfDefeatDialog = false
    this.desktopDialog.open()
  }

  public onCorrect() {
    this.engine.correct()
    this.state.showSelfDefeatDialog = false
    this.state.showSchnapszahlDialog = false
  }

  private created() {
    const factory = new GameStateFactory()
    const gameplay = window.gameplay || new Gameplay()
    const state = factory.build(gameplay)
    this.engine = new GameEngine(state)
  }

  private mounted() {
    this.desktopDialog = this.$refs.desktopDialog as DartboardInputDialog
    this.worldmapDialog = this.$refs.worldmapDialog as WorldmapDialog
    this.board = this.$refs.board as DartBoard
    // this.showAdDialog("revanche");

    // set up SignalR to receive shots from dart board
    if (process.env.VUE_APP_SIGNALR_SCORE_HUB) {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(process.env.VUE_APP_SIGNALR_SCORE_HUB!)
        .build()
      connection.on('scoreReceived', (data: string) => {
        data = data.replace('.', ',')
        if (!this.board2score[data]) {
          alert(`Der Score vom Dartboard konnte nicht verarbeitet werden: ${data}`)
        } else {
          if (this.board2score[data] === 'next') {
            if (this.state.showSelfDefeatDialog) {
              this.onDefeat()
            } else if (this.state.showSchnapszahlDialog) {
              this.onSchnapszahl()
            } else if ((this.$refs.adDialog as any).isOpen()) {
              (this.$refs.adDialog as any).next()
            } else if (this.state.isGameOver) {
              this.showAdDialog('newgame')
            } else {
              this.onNext()
            }
          } else { 
            this.engine.shoot(this.board2score[data])
          }
        }
      })
      connection.start()
    }

    // this.boardWindow = window.open('board.html', 'board', 'width=800,height=600')

    // if (this.boardWindow) {
    //   const boardWindow = this.boardWindow
    //   setTimeout(() => {
    //     boardWindow.document.getElementById('use27')!.style.fill = 'yellow'
    //   }, 1000)
    // }
  }
}
