import SegmentRule from '@/engine/rules/segment-rule'
import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'
import Segment from '@/engine/models/segment'
import Segments from '@/engine/common/segments'
import audioService from '@/services/audio-service'
import States from '@/engine/common/states'
import handleDestroy from '@/engine/components/handle-destroy'

export default class AbsoluteProConquestRule extends SegmentRule {
  protected segmentSatisfied(
    turnState: TurnState,
    state: GameState,
    segment: Segment,
  ): boolean {
    return (
      state.gameplay.enabledConquerAll === false &&
      // state.gameplay.enabledBullForLand === false &&
      state.gameplay.enabledAbsolutePro === true &&
      // segment.id !== "b00" &&
      // segment.id !== "e00" &&
      segment.owner == null &&
      Segments.checkForTopRegionIfRequired(state, segment)
    )
  }

  protected segmentAction(
    turnState: TurnState,
    state: GameState,
    segment: Segment,
  ): GameState {
    // Current shot is dxx. Check if we hit txx before.
    if (state.shotAlph === 'd' && state.hitTriple(state.shot)) {
      if (state.gameplay.enabledBullForLand) {
        this.handleBullForLand(turnState, state, segment)
      } else {
        this.conquer(turnState, state, segment)
      }

      // Handles the destroy button.
      if (state.gameplay.enabledDestroy) {
        handleDestroy(state)
      } else if (States.allCountriesConqueredChange(state)) {
        // If all regions have been conquered, play time to attack
        // sound.
        audioService.playTimeToAttack()
      }
    }
    return state
  }

  private handleBullForLand(
    turnState: TurnState,
    state: GameState,
    segment: Segment,
  ) {
    if (state.hasHit('e00')) {
      // if we also hit bullseye conquer the region.
      this.conquer(turnState, state, segment)
    } else if (state.hasHit('b00') && state.conquests === 0) {
      // With a bull, we can only conquer a single region.
      this.conquer(turnState, state, segment)
    }
  }

  private conquer(turnState: TurnState, state: GameState, segment: Segment) {
    Segments.conquerRegion(state, segment)
    if (turnState.turnIncomplete) {
      audioService.playConquerRegion()
    }
  }
}
