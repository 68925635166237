


















































































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import GameState from '@/engine/models/game-state'
import Player from '@/engine/models/player'
import Team from '@/engine/models/team'

@Component
export default class KeypadInputDialog extends Vue {
  @Prop()
  private state!: GameState

  private dialog: boolean = false
  private active: number = 0

  private showNumpad: boolean = false

  private num: string = ''
  private alph: string = ''

  get player(): Player {
    return this.state.player
  }

  get team(): Team {
    return this.state.team
  }

  get numShots(): number {
    return this.state.history.length
  }

  get numDarts(): number {
    return this.state.gameplay.numDarts
  }

  get completed(): boolean {
    return this.numShots === this.numDarts
  }

  public open(n?: number) {
    this.showNumpad = true
    if (n === undefined) {
      this.active = 0
    } else {
      this.active = Math.min(n, this.numShots)
    }
    this.dialog = true
  }

  private idFromNumber(r: number, s: number): string {
    return `${r * 5 + s}`.padStart(2, '0')
  }

  private isSelected(x: string): boolean {
    return (
      this.active < this.numShots && this.state.history[this.active].includes(x)
    )
  }

  @Watch('state', { deep: true })
  private stateChanged(val: GameState) {
    if (val.isGameOver || val.showSchnapszahlDialog || val.selfDefeat) {
      this.close()
    }
  }

  private close() {
    this.dialog = false
  }

  private toggle(n: number) {
    if (n <= this.numShots) {
      this.active = n
    }
  }

  private selectNum(num: string) {
    this.num = num
    if (this.alph === 'e' || this.alph === 'b' || this.alph === '-') {
      this.alph = ''
    }
    this.select(this.active)
  }

  private selectAlph(alph: string) {
    this.alph = alph
    if (this.num === '00' || this.num === '--') {
      this.num = ''
    }
    this.select(this.active)
  }

  private selectAlphNum(alph: string, num: string) {
    this.alph = alph
    this.num = num
    this.select(this.active)
  }

  private select(n: number) {
    const id = this.alph + this.num
    if (this.num.length > 0 && this.alph.length > 0) {
      if (this.completed) {
        this.$emit('replace', n, id)
      } else {
        this.$emit('shot', id)
      }

      if (this.active < this.numDarts - 1) {
        this.showNumpad = true
        this.active++
      } else {
        this.active = 0
        // this.close();
      }
    } else if (this.num.length > 0) {
      const shot = this.state.history[n]
      this.$emit('replace', n, `${shot.substr(0, 1)}${this.num}`)
    } else if (this.alph.length > 0) {
      const shot = this.state.history[n]
      this.$emit('replace', n, `${this.alph}${shot.substr(1, 2)}`)
    }

    this.num = ''
    this.alph = ''
  }
}
