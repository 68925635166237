import Team from './team'
import Segment from './segment'
import Gameplay from './gameplay'
import Player from './player'
import States from '../common/states'

declare global {
  interface Window {
    __GAME_STATE_HISTORY__: GameState[]
  }
}

export default class GameState {
  public previousId: number | null = null

  public state: 'running' | 'schnapszahl' | 'game-over' = 'running'

  public gameplay: Gameplay = new Gameplay()
  public teams: Team[] = []
  public segments: Segment[] = []

  /** 
   * The number of rounds played. 
   * A round consists of turns. 
   * Each active player has one turn per round. 
   */
  public round: number = 1

  /** The ID of the current team. */
  public teamId: number = 0

  /** The ID of the current player. */
  public playerId: number = 0

  /** Points payed for NÜX. */
  public nuexPayed: number = 0

  /** Number of rents payed in a single turn. */
  public rents: number = 0

  /** Rent payed in a single turn. */
  public rentPayed: number = 0

  /** Points (rent) dealt to other players in a single turn. */
  public pointsDealt: number = 0

  /** Number of bullseyes dealt to other players in a single turn. */
  public bullseyesDealt: number = 0

  /** Number of bulls dealt to other players in a single turn. */
  public bullsDealt: number = 0

  /** Number of successful segment conquests in a single turn. */
  public segConquests: number = 0

  /** Number of successful region conquests in a single turn. */
  public conquests: number = 0

  /** Number of constructed houses in a single turn. */
  public houses: number = 0

  /** Number of constructed villas in a single turn. */
  public villas: number = 0

  /** Number of constructed buildings in a single turn. */
  public buildings: number = 0

  /**
   * Classical points scored in a single turn. Equals the sum of 
   * the hit segment points. Required to determine a Schnapszahl.
   */
  public points: number = 0

  /** True iff the player eliminated himself. */
  public selfDefeat: boolean = false

  public showSelfDefeatDialog: boolean = false

  /** 
   * The history of shots in a single turn. 
   * Does not include the current shot.
   */
  public history: string[] = []

  /** The current shot. */
  public shot: string = ''

  public showSchnapszahlDialog: boolean = false

  /**
   * Returns the current team.
   */
  get team(): Team {
    return this.teams[this.teamId]
  }

  /**
   * Returns the team with this id.
   * 
   * @param id The team ID.
   */
  public teamWithId(id: number): Team {
    return this.teams[id]
  }

  /**
   * Returns the list of all other teams.
   */
  get otherTeams(): Team[] {
    return this.teams.filter((team) => team.id !== this.teamId)
  }

  get hasZeroedTeams(): boolean {
    return this.zeroedTeams.length > 0
  }

  /**
   * Returns all teams that are still active but have 0 points.
   */
  get zeroedTeams(): Team[] {
    return this.teams.filter(
      (team) => team.score <= 0 && team.state === 'active',
    )
  }

  get clearedTeams(): Team[] {
    return this.teams.filter((team) => team.state === 'cleared')
  }

  get defeatedTeams(): Team[] {
    return this.teams.filter((team) => team.state === 'defeated')
  }

  /**
   * Returns the current player.
   */
  get player(): Player {
    return this.team.players[this.playerId]
  }

  /**
   * Returns a list of all players from all teams.
   */
  get players(): Player[] {
    return this.teams.flatMap((t) => t.players)
  }

  /**
   * Returns true iff each team consists of only a single player.
   */
  get isSingle(): boolean {
    return this.teams.every((t) => t.players.length === 1)
  }

  /**
   * Returns true iff there are two teams with two players each.
   */
  get isDouble(): boolean {
    return this.gameplay.numTeams === 2 && this.gameplay.numPlayers === 2
  }

  get bullsEyeEnabled(): boolean {
    return this.gameplay.enabledBull
      || States.allCountriesConquered(this)
      || States.allOrNothingAndDestroyAndVilla(this)
  }

  get bullEnabled(): boolean {
    return this.bullsEyeEnabled && this.gameplay.enabledBBE
  }

  /**
   * Returns the number of active teams.
   */
  get numActiveTeams(): number {
    return this.teams.filter((t) => t.score > 0).length
  }

  get isGameOver(): boolean {
    return this.state === 'game-over'
  }

  get isTurnOver(): boolean {
    return this.numShots >= this.gameplay.numDarts
  }

  get isOver(): boolean {
    return this.isTurnOver || this.isGameOver
  }

  get numShots(): number {
    return this.history.length
  }

  get shotAlph(): string {
    return this.shot.substring(0, 1)
  }

  get hasRemainingShots(): boolean {
    return this.numShots < this.gameplay.numDarts - 1
  }

  get hasConquered(): boolean {
    const prev = States.getStateOf(this.previousId)
    return prev !== null && prev.conquests < this.conquests
  }

  public hitCount(id: string): number {
    return this.history.filter((h) => h === id).length
  }

  public hasHit(id: string): boolean {
    return this.history.includes(id)
  }

  public hasNotHit(id: string): boolean {
    return this.hasHit(id) === false
  }

  public hitLower(id: string): boolean {
    // Wir müssen verhindern, dass bei Sequenzen wie lXX uXX uXX
    // beim zweiten uXX hitLower nochmals true zurückgibt.
    const start = this.history.lastIndexOf(id)
    return this.history.includes(`l${id.substring(1, 3)}`, start + 1)
  }

  public hitUpper(id: string): boolean {
    // TODO: Wäre wohl auch wie hitLower umzusetzen.
    return this.hasHit(`u${id.substring(1, 3)}`)
  }

  public hitLowerThenUpper(): boolean {
    return this.shotAlph === 'u' && this.hitLower(this.shot)
  }

  public hitTriple(id: string): boolean {
    // Wir müssen verhindern, dass bei Sequenzen wie tXX dXX dXX
    // beim zweiten dXX hitLower nochmals true zurückgibt.
    const start = this.history.lastIndexOf(id)
    return this.history.includes(`t${id.substring(1, 3)}`, start + 1)
  }

  public turnBeginning(): GameState | null {
    let cur: GameState | null = this
    let prev = cur.previousId
    while (prev != null && window.__GAME_STATE_HISTORY__[prev].team.id === this.team.id) {
      cur = window.__GAME_STATE_HISTORY__[prev]
      prev = cur.previousId
    }
    return cur
  }

  public getSegment(id: string): Segment | undefined {
    return this.segments.find((s) => s.id === id)
  }

  public getSegmentsForRegion(num: string): Segment[] {
    return this.segments.filter((s) => s.num === num)
  }

  /**
   * @deprecated Do not use anymore.
   */
  public getSegmentsForRegionNum(num: number): Segment[] {
    return this.getSegmentsForRegion(`${num}`.padStart(2, '0'))
  }

  public isRegionConquered(num: string): boolean {
    const lower = this.getSegment(`l${num}`)
    const upper = this.getSegment(`u${num}`)
    if (lower && upper) {
      return lower.owner !== null && lower.owner === upper.owner
    }
    return false
  }
}
