









import { Component, Vue } from 'vue-property-decorator'

@Component
export default class HelpDialog extends Vue {
  public dialog: boolean = false

  public open() {
    this.dialog = true
  }

  public created() {
    this.$bus.$on('open-help', () => {
      this.open()
    })
  }
}
