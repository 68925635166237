import { render, staticRenderFns } from "./state-icon.vue?vue&type=template&id=27c3da5d&scoped=true&"
import script from "./state-icon.vue?vue&type=script&lang=ts&"
export * from "./state-icon.vue?vue&type=script&lang=ts&"
import style0 from "./state-icon.vue?vue&type=style&index=0&id=27c3da5d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27c3da5d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
installComponents(component, {VBadge})
