import GameState from '@/engine/models/game-state'
import Segments from '@/engine/common/segments'
import Segment from '@/engine/models/segment'

declare global {
  interface Window {
    __GAME_STATE_HISTORY__: GameState[]
  }
}

// TODO: Sollte man wohl alles in game-state verschieben.
export default class States {

  public static getStateOf(historyId: number | null) {
    if (historyId === null) {
      return null
    }
    return window.__GAME_STATE_HISTORY__[historyId]
  }

  public static allCountriesConqueredChange(state: GameState): boolean {
    const previous = this.getStateOf(state.previousId)

    return previous !== null
      && this.countriesLeftToConquer(previous)
      && this.allCountriesConquered(state)
  }

  public static allCountriesConquered(state: GameState): boolean {
    // Zu berücksichtigen sind alle Regionen der Erde außer Nord- und
    // Südpol.
    let regions = state.segments.filter(
      (s) => s.id !== 'e00' && s.id !== 'b00',
    )
    // Bei Conquer All sind nur die l und u Segmente zu berücksichtigen.
    if (state.gameplay.enabledConquerAll) {
      regions = regions.filter((s) => s.alph === 'l' || s.alph === 'u')
    }
    // Alle diese segmente müssen einen Besitzer haben.
    return regions.every((s) => s.owner != null)
  }

  public static countriesLeftToConquer(state: GameState): boolean {
    return this.allCountriesConquered(state) === false
  }

  public static allOrNothingAndDestroyAndVilla(state: GameState): boolean {
    return state.gameplay.enabledAllOrNothing
      && state.gameplay.enabledDestroy
      && state.hasRemainingShots
      && state.villas > 0
  }

  public static getHitpoints(state: GameState, segment: Segment): number {
    const hitpoint = state.gameplay.getHitpointsFor(segment.id)
    if (hitpoint) {
      const baseRent = hitpoint.value
      return hitpoint.calculate(state, segment, baseRent)
    }
    return 0
  }

  public static pay(state: GameState, segment: Segment) {
    States.payRent(state, segment)
    if (state.gameplay.enabledAllOrNothing) {
      Segments.clearUncommittedRegions(state, state.team)
    }
    state.rents++
  }

  public static payForSegments(state: GameState, segments: Segment[]) {
    for (const segment of segments) {
      States.payRent(state, segment)
    }
    if (state.gameplay.enabledAllOrNothing) {
      Segments.clearUncommittedRegions(state, state.team)
    }
    state.rents++
  }

  public static deal(state: GameState, segment: Segment) {
    States.dealRent(state, segment)
    if (state.gameplay.enabledAllOrNothing) {
      for (const team of state.otherTeams) {
        Segments.clearUncommittedRegions(state, team)
      }
    }
  }

  private static dealRent(state: GameState, segment: Segment) {
    const points = States.getHitpoints(state, segment)
    for (const team of state.otherTeams) {
      team.pointsPayed += points
      team.score -= points
      state.pointsDealt += points
    }
  }

  private static payRent(state: GameState, segment: Segment) {
    const points = States.getHitpoints(state, segment)
    state.team.score -= points
    state.rentPayed += points
    state.team.pointsPayed += points
  }
}
