import kebabCase from 'lodash/kebabCase'
import Vue from 'vue'

const components = require.context(
  // The relative path of the components folder.
  '.',
  // Whether or not to look in subfolders.
  true,
  // Match vue files in component directories only.
  /.+\/components\/.+\.vue$/,
)

components.keys().forEach((filePath) => {
  // Get component config.
  const componentConfig = components(filePath)

  // Get kebab-case name of component.
  const componentName = kebabCase(
     // Strip the path and extension from the file path.
     filePath.replace(/^.+\/(.*)\.vue$/, '$1'),
  )

 // Register component globally.
  Vue.component(
   componentName,
   // Look for the component options on `.default`, which will
   // exist if the component was exported with `export default`,
   // otherwise fall back to module's root.
   componentConfig.default || componentConfig,
 )
})
