import SegmentRule from '@/engine/rules/segment-rule'
import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'
import Segment from '@/engine/models/segment'
import audioService from '@/services/audio-service'

export default class AbsoluteProBuildVillaRule extends SegmentRule {
  protected segmentSatisfied(
    turnState: TurnState,
    state: GameState,
    segment: Segment,
  ): boolean {
    return (
      state.gameplay.enabledConquerAll === false &&
      state.gameplay.enabledAbsolutePro === true &&
      segment.owner === state.team &&
      segment.alph === 'u' &&
      segment.houses === state.gameplay.maxHouses &&
      segment.villas < state.gameplay.maxVillas
    )
  }

  protected segmentAction(
    turnState: TurnState,
    state: GameState,
    segment: Segment,
  ): GameState {
    this.buildVillaInRegion(state, segment)
    this.handleAllOrNothingWithDestroy(state)

    if (turnState.turnIncomplete) {
      audioService.playConquerBuilding()
    }

    return state
  }

  private buildVillaInRegion(state: GameState, segment: Segment) {
    // TODO: Ersetzen durch state.segmentsinregion()
    for (const seg of state.segments) {
      if (seg.id.endsWith(segment.num)) {
        seg.villas++
      }
    }
    state.villas++
    state.buildings++
  }

  private handleAllOrNothingWithDestroy(state: GameState) {
    if (state.gameplay.enabledAllOrNothing && state.gameplay.enabledDestroy) {
      if (state.hasRemainingShots) {
        state.gameplay.enabledBull = true
        audioService.playTimeToAttack()
      }
    }
  }
}
