









import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

@Component
export default class StateIcon extends Vue {
  @Prop()
  private icon!: string

  @Prop({ default: 1 })
  private count!: number

  @Prop()
  private visible!: boolean

  get path() {
    return `img/state/${this.icon}.png`
  }
}
