import Rule from '@/engine/rules/rule'
import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'
import audioService from '@/services/audio-service'

export default class Victory11RRule implements Rule {

    public satisfied(turnState: TurnState, state: GameState): boolean {
        return state.gameplay.enabled11R
            && this.conquered11Regions(turnState, state)
    }

    public action(turnState: TurnState, state: GameState): GameState {
        audioService.playVictory()
        state.state = 'game-over'
        return state
    }

    private conquered11Regions(turnState: TurnState, state: GameState): boolean {
        return state.teams.some((team) => team.numRegions >= 11)
    }
}
