import Rule from '@/engine/rules/rule'
import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'

export default class ClearAbandonedEstateRule implements Rule {

    public satisfied(turnState: TurnState, state: GameState): boolean {
        return true
    }

    public action(turnState: TurnState, state: GameState): GameState {
        for (const segment of state.segments) {
            if (segment.owner == null) {
                segment.houses = 0
                segment.villas = 0
            }
        }
        return state
    }
}
