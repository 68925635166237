import Rule from '@/engine/rules/rule'
import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'

export default class SchnapszahlRule implements Rule {

    public satisfied(turnState: TurnState, state: GameState): boolean {
        return state.gameplay.enabled111
            && (state.points === 111 || state.points === 222)
    }

    public action(turnState: TurnState, state: GameState): GameState {
        state.state = 'schnapszahl'

        // Reset stats.
        state.team.pointsPayed = 0
        if (state.previousId) {
            const turnBeginningState = state.turnBeginning()
            if (turnBeginningState) {
                const teamPrev = turnBeginningState.teamWithId(state.teamId)
                // TODO: Alter code:
                // const teamPrev = turnBeginningState.teamWithId(state.team.id)
                state.team.score = teamPrev.score
            }
        }

        state.showSchnapszahlDialog = true
        return state
    }
}
