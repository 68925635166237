import SegmentRule from '@/engine/rules/segment-rule'
import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'
import Segment from '@/engine/models/segment'
import States from '@/engine/common/states'
import audioService from '@/services/audio-service'

export default class BullForLandBullsEyeRentRule extends SegmentRule {

    protected segmentSatisfied(turnState: TurnState, state: GameState, segment: Segment): boolean {
        return state.shot === 'e00'
            && state.gameplay.enabledBullForLand === true
    }

    protected segmentAction(turnState: TurnState, state: GameState, segment: Segment): GameState {
        if (States.allCountriesConquered(state)) {
            this.dealBullsEye(turnState, state, segment)
        } else if (state.gameplay.enabledBull) {
            this.dealBullsEye(turnState, state, segment)
        } else if (state.hasHit('e00') || state.hasHit('b00')) {
            States.pay(state, segment)
            if (turnState.turnComplete === false) {
                audioService.playPayRent()
            }
        }
        return state
    }

    private dealBullsEye(turnState: TurnState, state: GameState, segment: Segment) {
        States.deal(state, segment)
        state.bullseyesDealt++
        if (turnState.turnComplete === false) {
            audioService.playBullDeal()
        }
    }
}
