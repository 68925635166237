

















































// @ts-ignore
import { Howl, Howler } from 'howler'
import { Component, Prop, Vue } from 'vue-property-decorator'
import audioService from '@/services/audio-service'

@Component
export default class Navigation extends Vue {
  @Prop({ default: false })
  public permanent!: boolean

  @Prop({ default: '#4A4843' })
  public color!: string

  @Prop({ default: false })
  public play!: boolean

  public drawer: boolean = false
  public playing: boolean = false

  public forceOpen(route: string) {
    if (this.$route.path === '/setup') {
      location.reload()
    } else {
      this.$router.push(route)
    }
  }

  public open(route: string) {
    this.$router.push(route)
  }

  public toggleSidebar() {
    this.drawer = !this.drawer
    audioService.playOpenMenu()
  }

  public openHelp() {
    this.$bus.$emit('open-help')
  }

  public isSafari() {
    return (
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1
    )
  }
}
