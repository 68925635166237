

















import { Component, Prop, Vue } from 'vue-property-decorator'
import GameState from '@/engine/models/game-state'
import Team from '@/engine/models/team'
import Player from '@/engine/models/player'

@Component
export default class DartboardPlayer extends Vue {
  @Prop()
  private state!: GameState

  @Prop()
  private team!: Team

  @Prop()
  private player!: Player

  // get isOver(): boolean {
  //   return this.state.isTurnOver || this.state.isGameOver
  // }

  get color(): string {
    if (
      this.state.team.id === this.team.id &&
      this.state.player.id === this.player.id
    ) {
      return this.team.color
    }
    return '#4A4843'
  }

  private clickOnName() {
    this.$emit('name')
  }

  private next() {
    this.$emit('next')
  }
}
