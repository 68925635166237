class AudioService {

  public playConquerAllFreeField() {
    this.play('audio/conquer-all-free-field.mp3')
  }

  public playConquerBuilding() {
    this.play('audio/conquer-building.mp3')
  }

  public playConquerRegion() {
    this.play('audio/conquer-region.mp3')
  }

  public playNux() {
    this.play('audio/nux.mp3')
  }

  public playPayRent() {
    this.play('audio/pay-rent.mp3')
  }

  public playBullDeal() {
    this.play('audio/bull-deal.mp3')
  }

  public playDefeated() {
    this.play('audio/defeated.mp3')
  }

  public playVictory() {
    this.play('audio/victory.mp3')
  }

  public playGameOn() {
    this.play('audio/game-on.mp3')
    // this.playDelay('audio/game-on.mp3', 400)
  }

  public playTimeToAttack() {
    this.play('audio/time-to-attack.mp3')
  }

  public playOpenMenu() {
    this.play('audio/open-menu.mp3')
  }

  public playClickButton() {
    this.play('audio/click-button.mp3')
  }

  private play(file: string) {
    const audio = new Audio(file)
    audio.play()
  }

  private playDelay(file: string, delay: number) {
    setTimeout(() => {
      this.play(file)
    }, delay)
  }
}

export default new AudioService()
