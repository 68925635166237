import SegmentRule from '@/engine/rules/segment-rule'
import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'
import Segment from '@/engine/models/segment'
import Segments from '@/engine/common/segments'
import audioService from '@/services/audio-service'
import States from '@/engine/common/states'
import handleDestroy from '@/engine/components/handle-destroy'

export default class ConquestRule extends SegmentRule {
  protected segmentSatisfied(
    turnState: TurnState,
    state: GameState,
    segment: Segment,
  ): boolean {
    return (
      state.gameplay.enabledConquerAll === false &&
      state.gameplay.enabledBullForLand === false &&
      state.gameplay.enabledAbsolutePro === false &&
      segment.id !== 'b00' &&
      segment.id !== 'e00' &&
      segment.owner == null &&
      Segments.checkForTopRegionIfRequired(state, segment)
    )
  }

  protected segmentAction(
    turnState: TurnState,
    state: GameState,
    segment: Segment,
  ): GameState {
    // Current shot is uxx. Check if we hit lxx before.
    // if (state.shotAlph === 'u' && state.hitLower(state.shot)) {
    if (state.hitLowerThenUpper()) {
      Segments.conquerRegion(state, segment)

      // Handles the destroy button.
      if (state.gameplay.enabledDestroy) {
        handleDestroy(state)
      } else if (States.allCountriesConqueredChange(state)) {
        // If Easy Südpol Plus is enabled, activate Easy Südpol, because
        // all regions have been conquered.
        if (state.gameplay.enabledESPPlus) {
          state.gameplay.enabledBBE = true
        }
        // Don't play time to attack sound if Nordpol already active and
        // all regions have been conquered.
        const prev = States.getStateOf(state.previousId)
        if (prev && prev.gameplay.enabledBull) {
          return state
        }
        // If all regions have been conquered, play time to attack
        // sound.
        audioService.playTimeToAttack()
      }
    }

    if (turnState.turnIncomplete && state.hasConquered) {
      audioService.playConquerRegion()
    }

    return state
  }
}
