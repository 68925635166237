












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Segment from '@/engine/models/segment'
import GameState from '@/engine/models/game-state'

@Component
export default class WorldmapDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  private disabled!: boolean

  @Prop()
  private state!: GameState

  private dialog: boolean = false

  private readonly initialColor: string = '#CAB595'

  public get segments(): Segment[] {
    return this.state.segments
  }

  public open() {
    this.dialog = true
  }

  public update() {
    this.resetColors()
    this.setOwnedColors()
  }

  @Watch('state', { deep: true })
  private stateChanged(val: GameState, oldVal: GameState) {
    this.update()
  }

  private resetColors() {
    this.forAllRegions((id) => this.setFill(id, this.initialColor))
    this.forAllRegionsNames((id) => this.setColor(id, this.initialColor))
  }

  private setOwnedColors() {
    for (let i = 1; i <= 20; i++) {
      const id = `${i}`.padStart(2, '0')
      const rid = `r-${id}`
      const nid = `n-${id}`
      const segments = this.state.getSegmentsForRegion(id)
      if (segments.length > 0) {
        const region = segments[0]
        if (region.owner != null) {
          this.setFill(rid, region.owner.color)
          this.setColor(nid, region.owner.color)
        }
      }
    }
  }

  private setFill(id: string, color: string) {
    const elem: HTMLElement | null = this.$el.querySelector(`#${id}`)
    if (elem) {
      elem.style.fill = color
    }
  }

  private setColor(id: string, color: string) {
    // TODO: this.$el.querySelector(`#${id}`); funktioniert nicht. Warum?
    const elem: HTMLElement | null = document.getElementById(id)
    if (elem) {
      elem.style.color = color
    }
  }

  private mounted() {
    this.update()
  }

  private forAllRegions(action: (id: string) => any) {
    for (let i = 1; i <= 20; i++) {
      const id = `${i}`.padStart(2, '0')
      action(`r-${id}`)
    }
  }

  private forAllRegionsNames(action: (id: string) => any) {
    for (let i = 1; i <= 20; i++) {
      const id = `${i}`.padStart(2, '0')
      action(`n-${id}`)
    }
  }
}
