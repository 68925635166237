



















import { Component, Prop, Vue } from 'vue-property-decorator'
import GameState from '@/engine/models/game-state'
import Team from '@/engine/models/team'
import Player from '@/engine/models/player'

@Component
export default class VuePlayer extends Vue {
  @Prop()
  public state!: GameState

  get team(): Team {
    return this.state.team
  }

  get player(): Player {
    return this.state.player
  }
}
