

















import { Component, Prop, Vue } from 'vue-property-decorator'
import GameState from '@/engine/models/game-state'
import Team from '@/engine/models/team'
import Player from '@/engine/models/player'
import Players from '@/engine/common/players'

@Component
export default class ScoreBoard extends Vue {
  @Prop()
  private state!: GameState

  get players(): Player[] {
    const players = this.state.players
    const teamMax = this.state.gameplay.numTeams
    const playerMax = this.state.gameplay.numPlayers
    const ordered: Player[] = []
    let teamId: number = this.state.teamId
    let playerId: number = this.state.playerId
    for (let i = 0; i < players.length - 1; i++) {
      const next = Players.pickNext(teamId, playerId, teamMax, playerMax)
      teamId = next[0]
      playerId = next[1]
      ordered.push(this.state.teams[teamId].players[playerId])
    }
    return ordered
  }

  get teams(): Team[] {
    return this.state.teams
  }
}
