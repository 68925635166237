import GameState from '@/engine/models/game-state'
import audioService from '@/services/audio-service'

/**
 * Destroy Button (DES): If the player conquers a region, this will activate
 * Bull (Northpole) for the rest of the players turn.
 * 
 * If All-or-Nothing (AON) is selected as well, Bull (Northpole) will be
 * active for the rest of the turn if the player built a villa.
 */
export default function handleDestroy(state: GameState) {
  // if (state.conquests === 1) {
  // Enable bull for this turn.
  // If BBE has been activated in the setup stage, it will be
  // activated now as well as enabled bull is a prerequisite.
  state.gameplay.enabledBull = true
  // Play time to attack sound if it is not the last shot.
  if (state.hasRemainingShots) {
    audioService.playTimeToAttack()
  }
  // }
}
