












import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Stepper extends Vue {

  @Prop({ default: 1 })
  private step!: number

  @Prop({ default: true })
  private showTeamStep!: boolean
}
