














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Avatar extends Vue {
  @Prop({ required: true })
  private num!: number

  @Prop({ required: true })
  private color!: string
}
