import GameState from '@/engine/models/game-state'
import Players from './common/players'
import cloneDeep from 'lodash/cloneDeep'
import States from './common/states'
import TurnState from './models/turn-state'

declare global {
  interface Window {
    __GAME_STATE_HISTORY__: GameState[]
  }
}

export default class GameEngine {
  private state: GameState
  private turnState: TurnState

  constructor(state: GameState) {
    window.__GAME_STATE_HISTORY__ = []
    this.state = state
    this.commitState()
    this.turnState = new TurnState()
  }

  get currentState(): GameState {
    return this.state
  }

  public replaceShot(n: number, shot: string) {
    const shots = cloneDeep(this.state.history)
    shots[n] = shot
    this.undoTurn()
    this.executeManyShots(shots)
  }

  public shoot(shot: string) {
    this.executeShot(shot)
  }

  public next() {
    if (this.isGameOver()) {
      return
    }
    this.resetTurnState()
    const teamId = this.state.teamId
    const playerId = this.state.playerId
    this.pickNextPlayer(teamId, playerId)
    this.incrementRound(teamId)
  }

  public isGameOver(): boolean {
    return this.state.isGameOver
  }

  // TODO: private?
  public isTurnOver(): boolean {
    return this.state.isTurnOver
  }

  public reset() {
    if (window.__GAME_STATE_HISTORY__.length > 0) {
      const init = window.__GAME_STATE_HISTORY__[0]
      window.__GAME_STATE_HISTORY__ = []
      this.state = init
    }
  }

  public undoTurn() {
    while (window.__GAME_STATE_HISTORY__.length > 0 && this.state.history.length > 0) {
      this.undoShot()
    }
  }

  public undoShot() {
    const state = window.__GAME_STATE_HISTORY__.pop()
    if (state) {
      this.state = state
    }
  }

  public schnapszahl() {
    if (this.state.state === 'schnapszahl') {
      this.state.state = 'running'
      const teamId = this.state.teamId
      const playerId = this.state.playerId
      this.undoTurn()
      this.pickNextPlayer(teamId, playerId)
      this.incrementRound(teamId)
    }
  }

  public defeat() {
    // Self defeat. Process turn rules and continue with next player.
    this.processTurnRules()
    this.next()
  }

  public correct() {
    this.state.state = 'running'
  }

  private incrementRound(teamId: number) {
    if (this.state.teamId < teamId) {
      this.state.round++
    }
  }

  private executeManyShots(shots: string[]) {
    for (const shot of shots) {
      this.executeShot(shot)
    }
  }

  private executeShot(shot: string) {
    if (this.isTurnOver() || this.isGameOver()) {
      return
    }
    this.commitState()
    this.state.shot = shot
    this.scoreClassicPoints(shot)
    this.processRules()
    this.state.history.push(this.state.shot)
    // If it was the last shot in this turn, apply turn rules.
    if (this.isTurnOver()) {
      this.processTurnRules()
      this.turnState.turnComplete = true
    }
  }

  private commitState() {
    const previous = cloneDeep(this.state)
    const length = window.__GAME_STATE_HISTORY__.push(previous)
    this.state.previousId = length - 1
  }

  private scoreClassicPoints(shot: string) {
    const hp = this.state.gameplay.getHitpointsFor(shot)
    if (hp) {
      switch (shot.substr(0, 1)) {
        case 't':
          this.state.points += 3 * hp.value
          break
        case 'd':
          this.state.points += 2 * hp.value
          break
        default:
          this.state.points += hp.value
          break
      }
    }
  }

  private processRules() {
    for (const rule of this.state.gameplay.rules) {
      if (rule.satisfied(this.turnState, this.state)) {
        this.state = rule.action(this.turnState, this.state)
      }
    }
  }

  private processTurnRules() {
    for (const rule of this.state.gameplay.turnRules) {
      if (rule.satisfied(this.turnState, this.state)) {
        this.state = rule.action(this.turnState, this.state)
      }
    }
  }

  private resetTurnState() {
    // TODO: Alle Properties hier ins TurnState Objekt verschieben?
    this.state.history = []
    this.state.shot = ''
    this.state.nuexPayed = 0
    this.state.rents = 0
    this.state.rentPayed = 0
    this.state.pointsDealt = 0
    this.state.segConquests = 0
    this.state.conquests = 0
    this.state.bullseyesDealt = 0
    this.state.bullsDealt = 0
    this.state.houses = 0
    this.state.villas = 0
    this.state.buildings = 0
    this.state.points = 0
    this.resetPointsPayed()
    this.state.selfDefeat = false
    this.turnState = new TurnState()
  }

  private resetPointsPayed() {
    for (const team of this.state.teams) {
      team.pointsPayed = 0
    }
  }

  private pickNextPlayer(teamId: number, playerId: number) {
    const teamMax = this.state.gameplay.numTeams
    const playerMax = this.state.gameplay.numPlayers
    do {
      const next = Players.pickNext(teamId, playerId, teamMax, playerMax)
      teamId = next[0]
      playerId = next[1]
      this.state.teamId = next[0]
      this.state.playerId = next[1]
    } while (this.state.team.score <= 0)
  }
}
