import Rule from '@/engine/rules/rule'
import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'

export default class ResetDestroyRule implements Rule {

    public satisfied(turnState: TurnState, state: GameState): boolean {
        return state.gameplay.enabledDestroy
    }

    public action(turnState: TurnState, state: GameState): GameState {
        state.gameplay.enabledBull = false
        return state
    }
}
