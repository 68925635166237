



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import audioService from '@/services/audio-service'

@Component
export default class Home extends Vue {
  private open(route: string) {
    this.$router.push(route)
  }
}
