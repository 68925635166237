







import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Help extends Vue {

}
