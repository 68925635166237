import SegmentRule from '@/engine/rules/segment-rule'
import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'
import Segment from '@/engine/models/segment'
import Segments from '@/engine/common/segments'
import audioService from '@/services/audio-service'
import States from '@/engine/common/states'
import handleDestroy from '@/engine/components/handle-destroy'

export default class ConquerAllConquestRule extends SegmentRule {
  protected segmentSatisfied(
    turnState: TurnState,
    state: GameState,
    segment: Segment,
  ): boolean {
    return (
      state.gameplay.enabledConquerAll === true &&
      state.gameplay.enabledBullForLand === false &&
      state.gameplay.enabledAbsolutePro === false &&
      segment.id !== 'b00' &&
      segment.id !== 'e00' &&
      segment.owner == null &&
      Segments.checkForTopOrAboveRegionIfRequired(state, segment)
    )
  }

  protected segmentAction(
    turnState: TurnState,
    state: GameState,
    segment: Segment,
  ): GameState {
    segment.owner = state.team
    switch (segment.alph) {
      case 'd':
        this.buildHouse(segment, state, turnState)
        break
      case 't':
        this.buildVilla(segment, state, turnState)
        break
      default:
        this.conquer(turnState, state, segment)
        break
    }
    return state
  }

  private conquer(turnState: TurnState, state: GameState, segment: Segment) {
    if (this.hitRegion(state, segment)) {
      this.conquerRegion(state, turnState)
    } else {
      this.conquerSegment(state, turnState)
    }

    if (States.allCountriesConqueredChange(state)) {
      // If Easy Südpol Plus is enabled, activate Easy Südpol, because
      // all regions have been conquered.
      if (state.gameplay.enabledESPPlus) {
        state.gameplay.enabledBBE = true
      }
      // If all regions have been conquered, play time to attack sound.
      audioService.playTimeToAttack()
    }
  }

  private hitRegion(state: GameState, segment: Segment) {
    const lower = state.getSegment(`l${segment.num}`)
    const upper = state.getSegment(`u${segment.num}`)

    return (
      lower && lower.owner === state.team &&
      upper && upper.owner === state.team
    )
  }

  private conquerRegion(state: GameState, turnState: TurnState) {
    state.team.numRegions++
    state.conquests++

    // Handles the destroy button.
    if (state.gameplay.enabledDestroy) {
      handleDestroy(state)
    }

    if (turnState.turnComplete === false) {
      if (state.hasConquered) {
        audioService.playConquerRegion()
      }
    }
  }

  private conquerSegment(state: GameState, turnState: TurnState) {
    state.segConquests++
    if (turnState.turnComplete === false) {
      audioService.playConquerAllFreeField()
    }
  }

  private buildHouse(segment: Segment, state: GameState, turnState: TurnState) {
    segment.houses = 1
    state.houses++
    state.buildings++
    if (turnState.turnComplete === false) {
      audioService.playConquerBuilding()
    }
  }

  private buildVilla(segment: Segment, state: GameState, turnState: TurnState) {
    segment.houses = 1
    segment.villas = 1
    state.villas++
    state.buildings += 2
    if (turnState.turnComplete === false) {
      audioService.playConquerBuilding()
    }
  }
}
