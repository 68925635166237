import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import 'vuetify/src/stylus/app.styl'
import '@/assets/stylus/main.styl'
import '@/assets/stylus/variables.styl'

import '@/assets/style.css'

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    primary: '#4A4843',
    // secondary: '#b0bec5',
    // accent: '#8c9eff',
    // error: '#b71c1c',
  },
})
