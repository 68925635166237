import Rule from '@/engine/rules/rule'
import GameState from '@/engine/models/game-state'
import TurnState from '@/engine/models/turn-state'
import audioService from '@/services/audio-service'
import Segments from '@/engine/common/segments'

export default class NuexRule implements Rule {

    public satisfied(turnState: TurnState, state: GameState): boolean {
        let sat = state.segConquests === 0
            && state.conquests === 0
            && state.buildings === 0
            && state.pointsDealt === 0

        if (state.gameplay.enabledBullForLand) {
            sat = sat
                && state.hasNotHit('b00')
                && state.hasNotHit('e00')
        }

        return sat
    }

    public action(turnState: TurnState, state: GameState): GameState {
        if (turnState.turnComplete === false) {
            audioService.playNux()
        }
        state.team.score -= 25
        state.nuexPayed = 25
        state.team.pointsPayed += 25

        if (state.gameplay.enabledAllOrNothing) {
            Segments.clearUncommittedRegions(state, state.team)
        }

        return state
    }
}
