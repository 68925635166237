







import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class AvatarSelection extends Vue {
  @Prop({ type: Number })
  private value!: number

  private select(num: number) {
    this.$emit('input', num)
  }
}
