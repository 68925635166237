





















import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import GameState from '@/engine/models/game-state'

@Component
export default class StateIcons extends Vue {
  @Prop()
  private state!: GameState
}
