


















import { Component, Prop, Vue } from 'vue-property-decorator'
import Team from '@/engine/models/team'
import GameState from '@/engine/models/game-state'

@Component
export default class DefeatDialog extends Vue {
  @Prop()
  private state!: GameState

  private nextPlayer() {
    this.$emit('defeat')
  }

  private correct() {
    this.$emit('correct')
  }
}
