


















import { Component, Prop, Vue } from 'vue-property-decorator'
import Gameplay from '@/engine/models/gameplay'

@Component
export default class GameConfig extends Vue {
  @Prop()
  public gameplay!: Gameplay

  @Prop({ default: false })
  public small!: boolean

  get originalGameplay() {
    return window.__GAME_STATE_HISTORY__[0].gameplay
  }
}
