

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import audioService from '@/services/audio-service'

@Component
export default class XToggle extends Vue {
  @Prop()
  private title!: string

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean

  @Prop()
  private value!: boolean

  private selected: boolean = this.value

  get selectedClass() {
    return { 'x-toggle-text--selected': this.selected }
  }

  get disabledClass() {
    return { 'x-toggle--disabled': this.disabled }
  }

  @Watch('value')
  private valueChanged(val: boolean) {
    this.selected = val
  }

  @Watch('disabled')
  private disabledChanged(val: boolean) {
    this.disabled = val
  }

  private toggle() {
    if (this.disabled === false) {
      audioService.playClickButton()
      this.$emit('input', !this.selected)
    }
  }
}
