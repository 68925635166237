import GameState from '@/engine/models/game-state'
import Segment from '@/engine/models/segment'
import Team from '@/engine/models/team'

export default class Segments {

    public static clearUncommittedRegions(state: GameState, team: Team) {
        if (state.gameplay.enabledAllOrNothing) {
            Segments.revokeConqueredRegions(state, team)
        }
        Segments.revokeOwnership(state, team)
    }

    public static teamOwnsBuildings(state: GameState, team: Team, segment: Segment) {
        const double = state.getSegment(`d${segment.num}`)
        const triple = state.getSegment(`t${segment.num}`)
        if (state.gameplay.enabledConquerAll) {
            return Segments.teamOwnsHouse(team, triple)
                && Segments.teamOwnsVilla(team, double)
        }
        return Segments.teamOwnsHouse(team, double)
            && Segments.teamOwnsVilla(team, triple)
    }

    public static teamOwnsHouse(team: Team, segment: Segment | undefined) {
        return segment
            && Segments.teamIsOwner(team, segment)
            && segment.houses > 0
    }

    public static teamOwnsVilla(team: Team, segment: Segment | undefined) {
        return segment
            && Segments.teamIsOwner(team, segment)
            && segment.villas > 0
    }

    public static teamIsOwner(team: Team, segment: Segment | undefined) {
        return segment
            && segment.owner !== null
            && segment.owner.id === team.id
    }

    public static conquerRegion(state: GameState, segment: Segment) {
        for (const seg of state.getSegmentsForRegion(segment.num)) {
            seg.owner = state.team
        }
        state.team.numRegions++
        state.conquests++
    }

    public static findTopFreeRegion(state: GameState): string | null {
        for (const num of state.gameplay.regionsTopDown) {
            const u = state.getSegment(`u${num}`)
            const l = state.getSegment(`l${num}`)
            if (l && u && (l.owner === null || u.owner === null)) {
                return num
            }
        }
        return null
    }

    public static checkForTopRegionIfRequired(state: GameState, segment: Segment): boolean {
        if (segment.id === 'b00' || segment.id === 'e00') {
            return true
        }
        if (state.gameplay.enabledTopToBottom) {
            const topRegion = Segments.findTopFreeRegion(state)!
            return topRegion === segment.num
        }
        return true
    }

    public static checkForTopOrAboveRegionIfRequired(state: GameState, segment: Segment): boolean {
        if (state.gameplay.enabledTopToBottom) {
            const topRegion = Segments.findTopFreeRegion(state)!
            return topRegion <= segment.num
        }
        return true
    }

    private static revokeConqueredRegions(state: GameState, team: Team) {
        for (let i = 1; i <= 20; i++) {
            const id = `${i}`.padStart(2, '0')
            const segments = state.getSegmentsForRegion(id)
            if (segments.length > 0) {
                const seg = segments[0]
                if (Segments.teamIsOwner(team, seg)) {
                    if (Segments.teamOwnsBuildings(state, team, seg) === false) {
                        team.numRegions--
                    }
                }
            }
        }
    }

    private static revokeOwnership(state: GameState, team: Team) {
        for (const seg of state.segments) {
            if (Segments.teamIsOwner(team, seg)) {
                if (Segments.teamOwnsBuildings(state, team, seg) === false) {
                    seg.owner = null
                }
            }
        }
    }
}
