



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class AdDialog extends Vue {
  private duration = 5 // min

  private videos = [
    { name: 'SOS Kinderdorf', id: '75Fdf8ZMoUU' },
    { name: 'Der Blitzableiter', id: 'TjNyKd72Cy0' },
  ]

  private dialog = false
  private player: any = null
  private videoId: string = ''
  private mode = 'revanche'

  public next() {
    this.$emit(this.mode)
    this.player.stopVideo()
    this.dialog = false
  }

  public isOpen() {
    return this.dialog
  }

  public open(mode: string) {
    this.dialog = true
    this.mode = mode
    this.pickVideo()
    this.play()
  }

  @Watch('videoId')
  private videoIdChanged(val: string, old: string) {
    if (val !== old) {
      this.videoId = val
      this.play()
    }
  }

  private pickVideo() {
    const d = new Date()
    const n = Math.floor(d.getMinutes() / this.duration) % this.videos.length
    this.videoId = this.videos[n].id
  }

  private ready(event: any) {
    this.player = event.target
  }

  private play() {
    this.$nextTick(() => {
      if (this.player !== null) {
        this.player.playVideo()
      }
    })
  }
}
