import Vue from 'vue'
import { PluginObject, VueConstructor } from 'vue'

declare module 'vue/types/vue' {
    interface Vue {
        $bus: Vue
    }
}

class VueBusPlugin implements PluginObject<any> {

    public install(vue: VueConstructor, options?: any): void {
        vue.prototype.$bus = new Vue()
    }
}

const vueBusPlugin = new VueBusPlugin()

Vue.use(vueBusPlugin)
