






import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Copyright extends Vue {
  get year() {
    return new Date().getUTCFullYear()
  }
}
