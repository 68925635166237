





import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Segment from '@/engine/models/segment'
import GameState from '@/engine/models/game-state'

@Component
export default class Worldmap extends Vue {

  @Prop({ type: Boolean, default: false })
  private showNumbers!: boolean

  @Prop()
  private state!: GameState

	private readonly initialColor: string = '#CAB595'

  get segments(): Segment[] {
    return this.state.segments
  }

  public update() {
    this.resetColors()
    this.setOwnedColors()
	}

  @Watch('state', { deep: true })
  private stateChanged(val: GameState, oldVal: GameState) {
    this.update()
  }

	private resetColors() {
		this.forAllRegions((id) => this.setFill(id, this.initialColor))
	}

  private setOwnedColors() {
		for (let i = 1; i <= 20; i++) {
			const num = `${i}`.padStart(2, '0')
			if (this.state && this.state.isRegionConquered(num)) {
				this.setRegionColor(num)
			}
    }
  }

	private setRegionColor(num: string) {
		const rid = `r-${num}`
		const region = this.state.getSegment(`l${num}`)
		if (region && region.owner) {
			this.setFill(rid, region.owner.color)
		}
	}

  private setFill(id: string, color: string) {
    const elem: HTMLElement | null = this.$el.querySelector(`#${id}`)
    if (elem) {
			elem.style.fill = color
    }
	}

  private setDisplay(id: string, display: string) {
    const elem: HTMLElement | null = this.$el.querySelector(`#${id}`)
    if (elem) {
			elem.style.display = display
    }
	}

  private mounted() {
		this.update()

		if (this.showNumbers === false) {
			for (let i = 1; i <= 20; i++) {
				const id = `${i}`.padStart(2, '0')
				const numId = `z-${id}`
				this.setDisplay(numId, 'none')
			}
		}
  }

  private forAllRegions(action: (id: string) => any) {
    for (let i = 1; i <= 20; i++) {
			const id = `${i}`.padStart(2, '0')
			action(`r-${id}`)
    }
  }
}
